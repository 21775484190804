import style from './ModalWindow.styl'
import {getEnums} from "../../../../utils/config";
import * as images from './images';
import { useAuth } from '@utils/hooks';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

export function ModalWindow(props){
    const {closeWindow} = props

const isLoginIn = useAuth();
const redirection = () => {
    if (isLoginIn){
        closeWindow(false);
    }else {
        window.location.replace( `?popup=${getEnums.popup.signUp}&theme=green`);
        closeWindow(false);
    }
   
}


    document.addEventListener('click', (e)=>{
        if(e.target.id === 'background'){
            closeWindow(false);
        }
    })
    return(
        <div id={'background'} className={style.wrapper}>
            <div className={style.content__wrapper}>
                <div onClick={()=>{closeWindow(false);}} className={style.closeButton}>X</div>
<div><img style={{marginLeft:'50px'}} src={images.logo}/></div>
                <img className={style.hand} src={images.hand}/>
                <div className={style.content}>

                    <div >
                      <div>Все курсы<br/>Чевостика<br/>на год</div>
                        <div className={style.sale__block}>со скидкой<br/><span >70%</span></div>
                    </div>
                    <div className={style.infoBlock}>
                        <div style={{display: 'flex'}}>
                            <div style={{
                                fontSize: '27px',
                                border: '3px solid #E7C351',
                                borderRadius: '30px',
                                padding: '10px'
                            }}>Всего<br/>1 990 ₽
                            </div>
                            <div style={{fontSize: '8px', marginLeft: '25px',}}>вместо
                                <div className={style.del}>6 600 ₽</div>
                                что составит<br/>выгоду 4610<br/>рублей за<br/>один год<br/>доступа
                            </div>
                        </div>
                        <div className={style.list}>
                            <div style={{display: 'flex', marginBottom: '10px'}}><img width={15} src={images.v}/>
                                <div style={{marginTop: '-2px', marginLeft: '10px'}}>более 100 игр</div>
                            </div>
                            <div style={{display: 'flex', marginBottom: '10px'}}><img width={15} src={images.v}/>
                                <div style={{marginTop: '-2px', marginLeft: '10px'}}>более 100 тестов</div>
                            </div>
                            <div style={{display: 'flex', marginBottom: '80px'}}><img width={15} src={images.v}/>
                                <div style={{marginTop: '-2px', marginLeft: '10px'}}>более 100 приключений</div>
                            </div>
                        </div>
                        <div className={style.button}><img width={89} height={67}
                                                           style={{marginTop: '-25px', marginLeft: '15px'}}
                                                           src={images.promo}/></div>
                        <ScrollLink
            to="subscribe"
            containerId="scroll-home"
            smooth={true}
            hashSpy={true}
            className={style.navLink}
          >
          <div onClick={redirection} className={style.buttonInvisible}><img width={89} height={67}
                                                           style={{marginTop: '-25px', marginLeft: '15px'}}
                                                           src={images.promo}/></div>
          </ScrollLink>
                        
                        
                        

                    </div>
                </div>


            </div>
        </div>
    )
}
